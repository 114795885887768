class GloITLoyaltyModalSuccessCard extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		//var modal = document.getElementById('modalCenter');
		//UIkit.modal(modal).show();
	}

	afterLoad() {
		this.$el = $(this);

		const checkPoints = setInterval(() => {
			const coinGainElement = document.querySelector('.coin-gain .coin-gained');
			const points = coinGainElement ? parseInt(coinGainElement.textContent, 10) : NaN;  

		if (!isNaN(points)) {
			clearInterval(checkPoints);


			$('#modal-loyalty-success').on('beforeshow', () => {
				const modal = document.querySelector('.loyalty-success-modal.success-modal-audiomystery');
	
				if (modal) {
	
					const pictureElement = modal.querySelector('.first-section-modal picture');
					if (pictureElement) {
						const sourceElement = pictureElement.querySelector('source');
						const imgElement = pictureElement.querySelector('img');
						if (points === 0) {
							if (sourceElement) {
								sourceElement.srcset = '/content/dam/glo-it/images/audiomystery/quiz-modal/bad-feedback-audiomystery.webp';
							}
	
							if (imgElement) {
								imgElement.src = '/content/dam/glo-it/images/audiomystery/quiz-modal/bad-feedback-audiomystery.webp';
								imgElement.alt = 'Audiomystery Failure Image';
							}
						} else {
							if (sourceElement) {
								sourceElement.srcset = '/content/dam/glo-it/images/audiomystery/quiz-modal/success-modal-image.webp';
							}
	
							if (imgElement) {
								imgElement.src = '/content/dam/glo-it/images/audiomystery/quiz-modal/success-modal-image.webp';
								imgElement.alt = 'Audiomystery Succcess Image';
							}
						}
	
					}
					const titleElement = modal.querySelector('.modal-title');
					if (titleElement) {
						if (points === 0) {
							titleElement.innerHTML = 'OPS! NON HAI<br>RISPOSTO BENE A<br>TUTTE LE DOMANDE!';
						} else {
							titleElement.textContent = 'BEN FATTO!';
						}
	
					}
				}
			});
	
            $('#modal-loyalty-success').on('hidden', () => window.location.reload());
        }
    }, 100); 
}


	beforeUpdate() {
	}

	afterUpdate() {
	}

	unload() {
	}
}

!customElements.get('bat-card-gloitloyaltymodalsuccess') &&
	customElements.define('bat-card-gloitloyaltymodalsuccess', GloITLoyaltyModalSuccessCard);
